import deleteIcon from "../../img/Icons/delete.png";

class bookingView {
  _parentElement = document.querySelector(".bookings__row--wrapper");

  addHandlerDelete(handler, summaries) {
    const allBookingDeleteBtns = document.querySelectorAll(
      ".bookings__row--delete-icon"
    );
    const bookingsSummaryTime = document.querySelector(
      ".bookings__summary-row--total-time"
    );
    const bookingsSummarySum = document.querySelector(
      ".bookings__summary-row--total-sum"
    );

    allBookingDeleteBtns.forEach((btn) => {
      btn.addEventListener("click", (e) => {
        e.preventDefault();

        handler(e.target.dataset.slug);

        e.target.parentElement.parentElement.outerHTML = "";

        bookingsSummaryTime.textContent = `${summaries.totalTime} min`;
        bookingsSummarySum.textContent = `${summaries.totalSum} €`;
      });
    });
  }

  renderBookings(allBookings) {
    let markup;

    allBookings.forEach((booking) => {
      if (booking.category === "face") {
        markup = this._generateOfferMarkup(booking, "Gesicht");
        this._parentElement.insertAdjacentHTML("beforeend", markup);
      }

      if (booking.category === "nail") {
        markup = this._generateOfferMarkup(booking, "Nagel");
        this._parentElement.insertAdjacentHTML("beforeend", markup);
      }

      if (booking.category === "body") {
        markup = this._generateOfferMarkup(booking, "Körper");
        this._parentElement.insertAdjacentHTML("beforeend", markup);
      }

      if (booking.category === "makeup") {
        markup = this._generateOfferMarkup(booking, "Make-Up");
        this._parentElement.insertAdjacentHTML("beforeend", markup);
      }
    });
  }

  renderSummary(summaries) {
    const markup = this._generateSummaryMarkup(
      summaries.totalTime,
      summaries.totalSum
    );
    this._parentElement.insertAdjacentHTML("afterend", markup);
  }

  _generateOfferMarkup(inputData, category) {
    return `<div class="bookings__row bookings__grid">
    <div class="bookings__row--behandlung">
      <div class="bookings__row--behandlung--kategorie">${category}</div>
      <div class="bookings__row--behandlung--name">${inputData.title}</div>
    </div>
    <div class="bookings__row--element">${inputData.duration} min</div>
    <div class="bookings__row--element">${inputData.price} €</div>
    <div class="bookings__row--delete"><img src="${deleteIcon}" alt="Delete Icon" class="bookings__row--delete-icon" data-slug="${inputData.slug}"></div>
  </div>`;
  }

  _generateSummaryMarkup(totalTime, totalSum) {
    return `<div class="bookings__summary-row bookings__grid">
    <span class="bookings__summary-row--element">Zusammenfassung</span>
    <span class="bookings__summary-row--element bookings__summary-row--total-time">${totalTime} min</span>
    <span class="bookings__summary-row--element bookings__summary-row--total-sum">${totalSum} €</span>
    <span class="bookings__summary-row--element"></span>
  </div>`;
  }
}

export default new bookingView();
