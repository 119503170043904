import { TIMEOUT_SEC } from "./config.js";
import { API_URL } from "./config.js";

const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timeout after ${s} second`));
    }, s * 1000);
  });
};

export const AJAX = async function (url) {
  try {
    const fetchPromise = fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const res = await Promise.race([fetchPromise, timeout(TIMEOUT_SEC)]);
    const data = await res.json();

    if (!res.ok) throw new Error(`${data.message} (${res.status})`);

    return data;
  } catch (err) {
    throw err;
  }
};

export const sendMail = async function (data) {
  try {
    const url = `${API_URL}/api/v1/booking`;

    const fetchPromise = fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const res = await Promise.race([fetchPromise, timeout(TIMEOUT_SEC)]);
    const resData = await res.json();

    if (!res.ok) throw new Error(`${resData.message} (${res.status})`);

    return resData;
  } catch (err) {
    throw err;
  }
};

export const sendContactFormMail = async function (data) {
  try {
    const url = `${API_URL}/api/v1/contact`;

    const fetchPromise = fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const res = await Promise.race([fetchPromise, timeout(TIMEOUT_SEC)]);
    const resData = await res.json();

    if (!res.ok) throw new Error(`${resData.message} (${res.status})`);

    return resData;
  } catch (err) {
    throw err;
  }
};
