class offerScrollbarView {
  handleOfferScrollbar() {
    const viewWidth = window.innerWidth;
    let observerThreshold = 0.28;

    if (viewWidth < 350) {
      observerThreshold = 0.05;
    } else if (viewWidth < 600) {
      observerThreshold = 0.1;
    } else if (viewWidth < 900) {
      observerThreshold = 0.15;
    } else if (viewWidth < 1200) {
      observerThreshold = 0.2;
    }

    const allOfferSections = document.querySelectorAll(".angebot");

    if (!allOfferSections) return;
    const sectionObserver = new IntersectionObserver(this._underlineButton, {
      root: null,
      threshold: observerThreshold,
    });

    allOfferSections.forEach(function (section) {
      sectionObserver.observe(section);
    });
  }

  _underlineButton(entries, observer) {
    const scrollMenu = document.querySelector(".angebot__scrollmenu");

    const scrollMenuBtns = document.querySelectorAll(
      ".angebot__scrollmenu--btn"
    );

    const viewWidth = window.innerWidth;
    let remValue = 10; //px

    if (viewWidth < 600) {
      remValue = 7; //px
    } else if (viewWidth < 900) {
      remValue = 8; //px
    } else if (viewWidth < 1200) {
      remValue = 9; //px
    }

    let scrollWidthSteps = [];
    let totalMenuWidth = 0;

    scrollMenuBtns.forEach((scrollMenuBtn, i) => {
      scrollWidthSteps.push(totalMenuWidth);
      totalMenuWidth += scrollMenuBtn.offsetWidth + 4 * remValue;
    });


    entries.forEach((entry) => {
      const sectionID = entry.target.id;

      const currentBtn = document.querySelector(`#btn-${sectionID}`);

      currentBtn.classList.remove("angebot__scrollmenu--active");

      if (entry.isIntersecting) {
        currentBtn.classList.add("angebot__scrollmenu--active");
        if (currentBtn.classList.value.includes("angebot__scrollmenu--btn-1")) {
          scrollMenu.scrollTo({
            left: scrollWidthSteps[0],
            behavior: "smooth",
          });
        } else if (
          currentBtn.classList.value.includes("angebot__scrollmenu--btn-2")
        ) {
          scrollMenu.scrollTo({
            left: scrollWidthSteps[1],
            behavior: "smooth",
          });
        } else if (
          currentBtn.classList.value.includes("angebot__scrollmenu--btn-3")
        ) {
          scrollMenu.scrollTo({
            left: scrollWidthSteps[2],
            behavior: "smooth",
          });
        } else if (
          currentBtn.classList.value.includes("angebot__scrollmenu--btn-4")
        ) {
          scrollMenu.scrollTo({
            left: scrollWidthSteps[3],
            behavior: "smooth",
          });
        }
      }
    });
  }
}

export default new offerScrollbarView();
