class productIconsSmoothRevealView {
  _productSection = document.querySelector("#produkte");
  _allProductContainers = document.querySelectorAll(
    ".produkte__container--text"
  );

  productIconsSmoothReveal() {
    if (!this._productSection || !this._allProductContainers) return;

    const sectionObserver = new IntersectionObserver(
      this._revealSection.bind(this),
      {
        root: null,
        threshold: 0.1,
      }
    );

    sectionObserver.observe(this._productSection);

    this._allProductContainers.forEach((container) => {
      container.classList.add("hidden");
    });
  }

  _revealSection(entries, observer) {
    entries.forEach((entry) => {
      // safe guard
      if (!entry.isIntersecting) return;

      this._allProductContainers.forEach((container) => {
        container.classList.remove("hidden");
        container.classList.add("produkte--active");
      });

      observer.unobserve(entry.target);
    });
  }
}

export default new productIconsSmoothRevealView();
