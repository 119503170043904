import View from "./view";

class contactFormView extends View {
  _parentElement = document.querySelector(".contact-form");

  _modal;
  _overlay;
  _btnCloseModal;
  _btnsOpenModal;
  _sendBtn;
  _inputFirstName;
  _inputLastName;
  _inputMail;
  _inputPhone;
  _inputMessage;

  handleContactForm(handler) {
    this._modal = document.querySelector(".modal");
    this._overlay = document.querySelector(".overlay");
    this._btnCloseModal = document.querySelector(".btn--close-modal");
    this._btnsOpenModal = document.querySelectorAll(".btn--show-modal");
    this._sendBtn = document.querySelector(".modal__form--btn--send");
    this._inputFirstName = document.querySelector(
      ".modal__form--input--firstName"
    );
    this._inputLastName = document.querySelector(
      ".modal__form--input--lastName"
    );
    this._inputMail = document.querySelector(".modal__form--input--mail");
    this._inputPhone = document.querySelector(".modal__form--input--phone");
    this._inputMessage = document.querySelector(".modal__form--input--message");

    this._btnsOpenModal.forEach((btn) => {
      btn.addEventListener("click", this._openModal.bind(this));
    });

    this._btnCloseModal.addEventListener("click", this._closeModal.bind(this));
    this._overlay.addEventListener("click", this._closeModal.bind(this));
    document.addEventListener(
      "keydown",
      function (e) {
        if (
          e.key === "Escape" &&
          !this._modal.classList.contains("hidden--modal")
        ) {
          this._closeModal();
        }
      }.bind(this)
    );

    this._sendBtn.addEventListener("click", (e) => {
      e.preventDefault();

      const firstName = this._inputFirstName.value;
      const lastName = this._inputLastName.value;
      const email = this._inputMail.value;
      const phone = this._inputPhone.value;
      const message = this._inputMessage.value;

      handler(firstName, lastName, email, phone, message);
    });
  }

  _openModal() {
    this._modal.classList.remove("hidden--modal");
    this._overlay.classList.remove("hidden--modal");
  }

  _closeModal() {
    this._modal.classList.add("hidden--modal");
    this._overlay.classList.add("hidden--modal");
  }

  renderSpinner() {
    document.body.innerHTML += '<div class="lds-roller__overlay"></div>';
    document.body.innerHTML +=
      '<div class="lds-roller lds-roller__checkout"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>';
  }

  _generateMarkup(flgTrue) {
    return `
  <div class="overlay hidden--modal"></div>
    <div class="modal hidden--modal">
      <button class="btn--close-modal">&times;</button>
      <h2 class="heading-2 modal__header">Kontaktformular</h2>
      <!-- <img
      src="src/img/Icons/underline-stroke.svg"
      alt="Kosmetik Eyeliner"
      class="modal__heading-underline"
      /> -->
      <form class="modal__form">
        <div class="modal__form--grid">
          <div class="modal__form--group vorname">
            <input
              type="text"
              class="modal__form--input modal__form--input--firstName"
              placeholder="Vorname"
              id="firstname"
              required
            />
            <label for="firstname" class="modal__form--label">Vorname</label>
          </div>

          <div class="modal__form--group nachname">
            <input
              type="text"
              class="modal__form--input modal__form--input--lastName"
              placeholder="Nachname"
              id="lastname"
              required
            />
            <label for="lastname" class="modal__form--label">Nachname</label>
          </div>

          <div class="modal__form--group email">
            <input
              type="email"
              class="modal__form--input modal__form--input--mail"
              placeholder="E-Mail Adresse"
              id="email"
              required
            />
            <label for="email" class="modal__form--label"
              >E-Mail-Adresse</label
            >
          </div>

          <div class="modal__form--group phone">
            <input
              type="tel"
              class="modal__form--input modal__form--input--phone"
              placeholder="Telefonnummer"
              id="phone"
            />
            <label for="phone" class="modal__form--label"
              >Telefonnummer</label
            >
          </div>

          <div class="modal__form--group anfrage">
            <textarea
              class="modal__form--input modal__form--input--message"
              id="subject"
              name="subject"
              placeholder="Hier ist Platz für deine Nachricht..."
              required
            ></textarea>
            <label class="modal__form--label" for="subject"
              >Deine Anfrage</label
            >
          </div>
        </div>

        <button class="btn modal__form--btn modal__form--btn--send">Senden</button>
      </form>
    </div>`;
  }
}

export default new contactFormView();
