class smoothScrollingView {
  handleSmoothScrolling() {
    document.querySelectorAll(".smooth-scrolling").forEach((btn) => {
      btn.addEventListener("click", function (e) {
        e.preventDefault();

        const id = e.currentTarget.getAttribute("href");
        const targetEl = document.querySelector(id);
        if (!targetEl) {
          if (id.includes(".html")) {
            // when coming from another page than index.html
            window.location.href = id; 
          } else {
            // when no valid section is found
            window.location.href = "/";
          }
        }

        targetEl.scrollIntoView({ block: "start", behavior: "smooth" });
      });
    });
  }
}

export default new smoothScrollingView();
