import { SLIDE_SHOW_INTERVAL } from "./../config.js";
import imgCustomer1 from "./../../img/Bewertungen/customer-1.jpg";
import imgCustomer2 from "./../../img/Bewertungen/customer-2.jpg";
import imgCustomer3 from "./../../img/Bewertungen/customer-3.jpg";
import imgCustomer4 from "./../../img/Bewertungen/customer-4.jpg";
import imgCustomer5 from "./../../img/Bewertungen/customer-5.jpg";
import imgCustomer6 from "./../../img/Bewertungen/customer-6.jpg";

class reviewSliderView {
  _parentElement = document.querySelector(".about-me__bewertungen--container");

  _reviewSlides = document.querySelectorAll(".bewertungen__slide");
  _btnLeft = document.querySelector(
    ".about-me__carousel-container--arrow-back"
  );
  _btnRight = document.querySelector(
    ".about-me__carousel-container--arrow-forward"
  );

  _dotContainer = document.querySelector(".about-me__carousel-indicators");
  _slider = document.querySelector(".about-me__carousel-container");

  _curSlide;
  _numSlides;

  render() {
    if (
      !this._reviewSlides ||
      !this._btnLeft ||
      !this._btnRight ||
      !this._dotContainer ||
      !this._slider
    )
      return;
    this.curSlide = 0;
    this._numSlides = this._reviewSlides.length;

    // Functions

    let timer = setInterval(() => {
      this._nextSlide();
    }, SLIDE_SHOW_INTERVAL * 1000);

    this._btnRight.addEventListener("click", () => {
      this._nextSlide();
      clearTimeout(timer);
    });

    this._btnLeft.addEventListener("click", () => {
      this.prevSlide();
      clearTimeout(timer);
    });

    this._slider.addEventListener("click", () => {
      clearTimeout(timer);
    });

    document.addEventListener("keydown", function (e) {
      if (e.key === "ArrowLeft") {
        this.prevSlide();
        clearTimeout(timer);
      }
      if (e.key === "ArrowRight") {
        this._nextSlide();
        clearTimeout(timer);
      }
    });

    this._dotContainer.addEventListener(
      "click",
      function (e) {
        if (e.target.classList.contains("carousel-indicator")) {
          this.curSlide = parseInt(e.target.dataset.slide);
          this._goToSlide(this.curSlide);
          this._activateDots(this.curSlide);
          clearTimeout(timer);
        }
      }.bind(this)
    );

    this.init();
  }

  _createDots() {
    this._reviewSlides.forEach((s, i) => {
      this._dotContainer.insertAdjacentHTML(
        "beforeend",
        `<span class="carousel-indicator" data-slide="${i}">&bull;</span>`
      );
    });
  }

  _activateDots(slide) {
    document
      .querySelectorAll(".carousel-indicator")
      .forEach((dot) => dot.classList.remove("carousel-indicator--active"));

    document
      .querySelector(`.carousel-indicator[data-slide="${slide}"]`)
      .classList.add("carousel-indicator--active");
  }

  _goToSlide(slide) {
    this._reviewSlides.forEach(
      (s, i) =>
        (s.style.transform = `translateX(${(i - this.curSlide) * 100}%)`)
    );
  }

  _nextSlide() {
    if (this.curSlide === this._numSlides - 1) {
      this.curSlide = 0;
    } else {
      this.curSlide++;
    }

    this._goToSlide(this.curSlide);
    this._activateDots(this.curSlide);
  }

  _preloadImgs() {
    // create object
    let imageObj = new Image();

    // set image list
    let images = new Array();
    images[0] = imgCustomer1;
    images[1] = imgCustomer2;
    images[2] = imgCustomer3;
    images[3] = imgCustomer4;
    images[4] = imgCustomer5;
    images[5] = imgCustomer6;

    images.forEach((img) => {
      imageObj.src = img;
    });
  }

  prevSlide() {
    if (this.curSlide === 0) {
      this.curSlide = this._numSlides - 1;
    } else {
      this.curSlide--;
    }

    this._goToSlide(this.curSlide);
    this._activateDots(this.curSlide);
  }

  init() {
    this._goToSlide(0);
    this._createDots();
    this._activateDots(0);
    this._preloadImgs();
  }
}

export default new reviewSliderView();
