import View from "./view";
import bannerImg from "./../../img/Baby-Banner/parental-leave.jpeg";

class bannerView extends View {
  _parentElement = document.querySelector(".contact-form");

  _modal;
  _overlay;
  _btnCloseModal;
  _btnsOpenModal;
  _sendBtn;
  _inputFirstName;
  _inputLastName;
  _inputMail;
  _inputPhone;
  _inputMessage;

  revealBanner(flgVisited) {
    this._modal = document.querySelector(".modal-banner");
    this._overlay = document.querySelector(".overlay");
    this._btnCloseModal = document.querySelector(
      ".modal-banner__btn--close-modal"
    );

    if (!flgVisited) {
      setTimeout(() => {
        this._modal.classList.remove("hidden--modal");
        this._overlay.classList.remove("hidden--modal");
      }, 3000);
    }

    this._btnCloseModal.addEventListener("click", this._closeModal.bind(this));
    this._overlay.addEventListener("click", this._closeModal.bind(this));
    document.addEventListener(
      "keydown",
      function (e) {
        if (
          e.key === "Escape" &&
          !this._modal.classList.contains("hidden--modal")
        ) {
          this._closeModal();
        }
      }.bind(this)
    );
  }

  _closeModal() {
    this._modal.classList.add("hidden--modal");
    this._overlay.classList.add("hidden--modal");
  }

  _generateMarkup() {
    return `
    <div class="modal-banner hidden--modal">
      <button class="modal-banner__btn--close-modal">&times;</button>
      <div class="modal-banner__picture--container">
        <img
          class="modal-banner__picture"
          src="${bannerImg}"
          alt="Banner Foto"
        />
      </div>
      <div class="modal-banner__textarea">
        <h2 class="modal-banner__heading mb-md">Lion ist da!</h2>
        <div class="modal-banner__text">
          <p>
            Mein kleiner Sonnenschein ist auf die Welt gekommen, weshalb ich
            mich gerade auf <b>Babypause</b> befinde. Bis dahin gibt es leider keine
            Möglichkeit eine Behandlung bei mir im Studio zu buchen.
          </p>
          <p class="mt-sm">
            Ich werde aber bald zurück sein und dir wieder zur
            Verfügung stehen. Bis dahin sieh dich gerne hier auf meiner neuen Website um.
          </p>
          <p class="mt-sm mb-md">Ich freue mich schon auf dich!</p>
        </div>
        <div class="modal-banner__signature">Deine Lisa</div>
        </div>
      </div>`;
  }
}

export default new bannerView();
