[
  {
    "category": "face",
    "title": "Gesichtsbehandlung Basic",
    "slug": "gesichtsbehandlung-basic",
    "duration": "70",
    "price": "59",
    "new": "nein",
    "onSale": "ja",
    "description": "Diese Behandlung umfasst Anamnese, Abreinigung, Hautanalyse, Peeling, Tiefenreinigung, Augenbrauen facionieren, Massage, Tagespflege.",
    "warning": "Die Behandlung wird passend auf deinen Hauttyp abegstimmt."
  },
  {
    "category": "face",
    "title": "Augenbrauen Färben",
    "slug": "augenbrauen-faerben",
    "duration": "30",
    "price": "8",
    "new": "nein",
    "onSale": "nein",
    "description": "Durch das färben deiner Augenbrauen ersparst du die jeden Morgen viel Zeit, denn du brauchst nicht mehr mit einem Augenbrauchenstifft deine Brauen in Form bringen. Der Farbton wird passend zu deinem Typ gewählt und lässt deine Brauen immer ganz natürlich gestylt aussehen, bis zu 4 Wochen lang.",
    "warning": ""
  },
  {
    "category": "face",
    "title": "Augenbrauen Facionieren",
    "slug": "augenbrauen-facionieren",
    "duration": "30",
    "price": "7",
    "new": "nein",
    "onSale": "nein",
    "description": "In kurzer Zeit werden deine Augenbrauen perfekt in Formgebracht passend zu deinen Gesichtszügen. Dazu werden ich eine Pinzette und gehe sehr vorsichtig dabei vor, damit die Behandlung ganz angenehm für dich ist.",
    "warning": ""
  },
  {
    "category": "face",
    "title": "Augenbrauen Harzen",
    "slug": "augenbrauen-harzen",
    "duration": "30",
    "price": "10",
    "new": "nein",
    "onSale": "nein",
    "description": "Deine Augenrbauen werden in kurzer Zeit perfekt in Form gebracht. Dazu werden ich ein warmes Wachs und Abziehstreifen, um ein exaktes Ergebnis zu erzielen. Der Klebestreifen wird gegen die Haarwuchsrichtung abgezogen.",
    "warning": "Durch das Harzen kann es kurz zu rötungen rund um deine Augenbrauen kommen. Diese klingt aber nach kurzer Zeit wieder ab."
  },
  {
    "category": "face",
    "title": "Brow Lifting",
    "slug": "brow-lifting",
    "duration": "50",
    "price": "55",
    "new": "nein",
    "onSale": "nein",
    "description": "Beim Brow Lifting werden (wie beim Lash Lifting auch) die Härchen verstärkt, sodass sie die Form über mehrere Wochen halten können. Die Augenbrauen werden also formbar gemacht, sodass du sie einfach in alle Richtungen kämmen und stylen kannst.",
    "warning": "Nicht geeignet für Personen mit Allergien, Schuppenflechte im Gesichtsbereich, Entzündungen/Verletzungen der Haut, Alopezie, Chemotherapie/Bestrahlung."
  },
  {
    "category": "face",
    "title": "Wimpern Färben",
    "slug": "wimpern-faerben",
    "duration": "15",
    "price": "8",
    "new": "nein",
    "onSale": "nein",
    "description": "Schluss mit dem nervigen Wimperntusche auftragen jeden Morgen. Durch das färben der Wimpern kannst du dir diese Zeit gut sparen. Die Wimpern bekommen eine auf deinen Haarton abgestimmte Farbe und erstahlen im neune fülligen Look.",
    "warning": ""
  },
  {
    "category": "face",
    "title": "Wimpern Lifting",
    "slug": "wimpern-lifting",
    "duration": "45",
    "price": "50",
    "new": "nein",
    "onSale": "nein",
    "description": "Für das Wimpernlifting werden die eigenen Härchen verwendet, wodurch das Ergebnis besonders natürlich wirkt.  Der Effekt ist ähnlich wie nach dem Benutzen einer Wimpernzange: Die Wimpern werden nach oben gebogen und bekommen so den perfekten Schwung – nur, dass dieser beim Lifting bis zu acht Wochen anhält.",
    "warning": "Nicht geeignet für Personen mit Allergien (Farbpigmente, Polymer, Keratin, Kollagen, Vitamin A und C, Seidenproteine) oder Personen mit Augenkrankheiten (Entzündungen, Herpes) bzw. nach einer Chemotherapie. Bei Schwangerschaft und Stillzeit nach Absprache mit dem Arzt."
  },
  {
    "category": "nail",
    "title": "Maniküre",
    "slug": "manikuere",
    "duration": "30",
    "price": "32",
    "new": "nein",
    "onSale": "nein",
    "description": "Diese Behandlung umfasst ein Handbad, Schneiden/Feilen der Fingernägel, entfernen der Nagelhaut, Nagelöl, Handmassage mit Handcreme.",
    "warning": ""
  },
  {
    "category": "nail",
    "title": "Maniküre inkl. Gellack",
    "slug": "manikuere-inkl.-gellack",
    "duration": "45",
    "price": "42",
    "new": "nein",
    "onSale": "nein",
    "description": "Diese Behandlung umfasst ein Handbad, Schneiden/Feilen der Fingernägel, entfernen der Nagelhaut, aufragen Gellack, Nagelöl, Handmassage mit Handcreme.",
    "warning": ""
  },
  {
    "category": "nail",
    "title": "Handpeeling und Packung inkl. Massage",
    "slug": "handpeeling-und-packung-inkl.-massage",
    "duration": "30",
    "price": "39",
    "new": "nein",
    "onSale": "nein",
    "description": "Wellness Pur für spröde, raue, rissige Hände. Mit einem wohlriechenden Peeling werden abgestorbene Hautschüppchen sanft entfernt und anschließend die trockene Haut mit einer revitalisierenden und feuchtigkeitsspendenen Packung genährt. Für glatte, seidig gepflegte Hände.",
    "warning": ""
  },
  {
    "category": "nail",
    "title": "Gelnägel Neuset",
    "slug": "gelnaegel-neuset",
    "duration": "120",
    "price": "60",
    "new": "nein",
    "onSale": "nein",
    "description": "Hier wird zunächst der Naturnagel angeraut, um die Haftung des Gels zu sichern. Danach wird das Gel aufgetragen und unter einer LED/UV Lampe ausgehärtet. Wähle zwischen vielen verschiedenen Farben und Designs für den perfekten Finish. ",
    "warning": "Deine Nägel halten nach dieser Behandlung ca. drei bis vier Wochen."
  },
  {
    "category": "nail",
    "title": "Acrylnägel Neuset",
    "slug": "acrylnaegel-neuset",
    "duration": "120",
    "price": "60",
    "new": "nein",
    "onSale": "nein",
    "description": "Für Acrylnägel wird ein Gemisch aus Pulver und einer speziellen Flüssigkeit verwendet wird. Das Acryl kommt auf deinen Naturnagel und härtet an der Luft aus. Wähle zwischen vielen verschiedenen Farben und Designs für den perfekten Finish. ",
    "warning": "Deine Nägel halten nach dieser Behandlung ca. vier bis fünf Wochen."
  },
  {
    "category": "nail",
    "title": "Nägel Refill",
    "slug": "naegel-refill",
    "duration": "90",
    "price": "45",
    "new": "nein",
    "onSale": "nein",
    "description": "Bei dieser Behandlung wird die herausgewachsene Gel- oder Acrylmodellage aufgefüllt.",
    "warning": ""
  },
  {
    "category": "face",
    "title": "Lymphdrainage",
    "slug": "lymphdrainage",
    "duration": "40",
    "price": "40",
    "new": "ja",
    "onSale": "nein",
    "description": "Diese Therapieform bietet sich als Gesichtsmassage an, um zum Beispiel Tränensäcke zu verringern oder das Hautbild zu verfeinern (Akne). Zudem kann diese Technik bei Kopfschmerzen und Migräne helfen. Insgesamt hat die kosmetische Lymphdrainage eine entspannende, reinigende und krampflösende Wirkung.",
    "warning": "Diese Behandlung ist nicht geeignet für Personen mit akuten Infektionen und Entzündungen, Karzinomtherapie, Herzinsuffizenz oder Hypotonie."
  },
  {
    "category": "face",
    "title": "Gesichtsbehandlung Spezial",
    "slug": "gesichtsbehandlung-spezial",
    "duration": "90",
    "price": "85",
    "new": "ja",
    "onSale": "ja",
    "description": "Diese Behandlung umfasst Anamnese, Abreinigung, Hautanalyse, Peeling, Tiefenreinigung, Augenbrauen facionieren, Ampulle und Ultraschall, Massage, Maske, Tagespflege.",
    "warning": "Die Behandlung wird passend auf deinen Hauttyp abegstimmt."
  },
  {
    "category": "face",
    "title": "Gesichtsbehandlung Männer Basic",
    "slug": "gesichtsbehandlung-maenner-basic",
    "duration": "55",
    "price": "56",
    "new": "ja",
    "onSale": "ja",
    "description": "Diese Behandlung umfasst Anamnese, Abreinigung, Hautanalyse, Peeling, Tiefenreinigung, Massage, Maske, Tagespflege.",
    "warning": "Die Behandlung wird passend auf deinen Hauttyp abegstimmt."
  },
  {
    "category": "face",
    "title": "Gesichtsbehandlung Männer Spezial",
    "slug": "gesichtsbehandlung-maenner-spezial",
    "duration": "70",
    "price": "80",
    "new": "ja",
    "onSale": "ja",
    "description": "Diese Behandlung umfasst Anamnese, Abreinigung, Hautanalyse, Peeling, Tiefenreinigung, Ampulle und Ultraschall, Massage, Maske, Tagespflege.",
    "warning": "Die Behandlung wird passend auf deinen Hauttyp abegstimmt."
  },
  {
    "category": "face",
    "title": "Gesichtsbehandlung Unreine Haut",
    "slug": "gesichtsbehandlung-unreine-haut",
    "duration": "50",
    "price": "43",
    "new": "ja",
    "onSale": "ja",
    "description": "Diese Behandlung umfasst Anamnese, Abreinigung, Hautanalyse, Peeling, Tiefenreinigung, Hochfrequenz, Tagespflege.",
    "warning": "Diese Behandlung ist sehr schonend in der Anwendung und speziell für Personen mit starker unreiner Haut."
  },
  {
    "category": "face",
    "title": "Gesicht und Hände Premium",
    "slug": "gesicht-und-haende-premium",
    "duration": "100",
    "price": "125",
    "new": "ja",
    "onSale": "ja",
    "description": "Ein Rundum Paket für Gesicht und Hände mit Anamnese, Abreinigung, Hautanalyse, Peeling, Tiefenreinigung, Augenbrauen facionieren, Ampulle und Ultraschall, Massage, Maske, Tagespflege, Handpeeling, Handpackung und Massage.",
    "warning": "Die Behandlung wird passend auf deinen Hauttyp abegstimmt."
  },
  {
    "category": "face",
    "title": "Needling",
    "slug": "needling",
    "duration": "60",
    "price": "120",
    "new": "ja",
    "onSale": "nein",
    "description": "Das Micro Needling dient vor allem der Verjüngung der Haut. Aber auch Pigmentstörungen können mit dieser Behandlung reduziert werden. Needling ist optimal als Behandlung gegen Falten, schlechte Hautstruktur, Narben, schlaffes Gewebe, sonnengeschädigte Haut.",
    "warning": "Für einen langfristes schöneres Hautbild empfehle ich eine Kur mit mindestens 6 Behandlungen."
  },
  {
    "category": "face",
    "title": "Needling 6er Kur",
    "slug": "needling-6er-kur",
    "duration": "60",
    "price": "660",
    "new": "ja",
    "onSale": "ja",
    "description": "Das Micro Needling dient vor allem der Verjüngung der Haut. Aber auch Pigmentstörungen können mit dieser Behandlung reduziert werden. Needling ist optimal als Behandlung gegen Falten, schlechte Hautstruktur, Narben, schlaffes Gewebe, sonnengeschädigte Haut.",
    "warning": "Die Abstände der Behandlungen werden individuell auf deine Hautvertäglichkeit angepasst."
  },
  {
    "category": "body",
    "title": "Harzen: Achseln",
    "slug": "harzen:-achseln",
    "duration": "15",
    "price": "15",
    "new": "nein",
    "onSale": "nein",
    "description": "Harzen ist eine Haarentfernungsmethode mit warmem Wachs. Dabei wird das Warmwachs in Wuchsrichtung in den Achseln aufgetragen. Diese wird im Anschluss mit einem Vliesstreifen gegen die Wuchsrichtung mit einem Ruck abgezogen. Dabei werden die Haare inkl. Haarwurzel entfernt.",
    "warning": "Um langfristig weniger Haarwuchs zu erreichen empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Harzen: Beine bis Knie",
    "slug": "harzen:-beine-bis-knie",
    "duration": "30",
    "price": "25",
    "new": "nein",
    "onSale": "nein",
    "description": "Harzen ist eine Haarentfernungsmethode mit warmem Wachs. Dabei wird das Warmwachs in Wuchsrichtung auf Unterschenkel aufgetragen. Diese wird im Anschluss mit einem Vliesstreifen gegen die Wuchsrichtung mit einem Ruck abgezogen. Dabei werden die Haare inkl. Haarwurzel entfernt.",
    "warning": "Um langfristig weniger Haarwuchs zu erreichen empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Harzen: Beine komplett",
    "slug": "harzen:-beine-komplett",
    "duration": "45",
    "price": "45",
    "new": "nein",
    "onSale": "nein",
    "description": "Harzen ist eine Haarentfernungsmethode mit warmem Wachs. Dabei wird das Warmwachs in Wuchsrichtung auf die Beine aufgetragen. Diese wird im Anschluss mit einem Vliesstreifen gegen die Wuchsrichtung mit einem Ruck abgezogen. Dabei werden die Haare inkl. Haarwurzel entfernt.",
    "warning": "Um langfristig weniger Haarwuchs zu erreichen empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Harzen: Oberlippe",
    "slug": "harzen:-oberlippe",
    "duration": "10",
    "price": "7",
    "new": "nein",
    "onSale": "nein",
    "description": "Harzen ist eine Haarentfernungsmethode mit warmem Wachs. Dabei wird das Warmwachs in Wuchsrichtung auf die Oberlippe aufgetragen. Diese wird im Anschluss mit einem Vliesstreifen gegen die Wuchsrichtung mit einem Ruck abgezogen. Dabei werden die Haare inkl. Haarwurzel entfernt.",
    "warning": "Um langfristig weniger Haarwuchs zu erreichen empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Harzen: Kinn",
    "slug": "harzen:-kinn",
    "duration": "10",
    "price": "7",
    "new": "nein",
    "onSale": "nein",
    "description": "Harzen ist eine Haarentfernungsmethode mit warmem Wachs. Dabei wird das Warmwachs in Wuchsrichtung auf das Kinn aufgetragen. Diese wird im Anschluss mit einem Vliesstreifen gegen die Wuchsrichtung mit einem Ruck abgezogen. Dabei werden die Haare inkl. Haarwurzel entfernt.",
    "warning": "Um langfristig weniger Haarwuchs zu erreichen empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Harzen: Bikinizone",
    "slug": "harzen:-bikinizone",
    "duration": "20",
    "price": "20",
    "new": "nein",
    "onSale": "nein",
    "description": "Harzen ist eine Haarentfernungsmethode mit warmem Wachs. Dabei wird das Warmwachs in Wuchsrichtung in der Bikinzone aufgetragen. Diese wird im Anschluss mit einem Vliesstreifen gegen die Wuchsrichtung mit einem Ruck abgezogen. Dabei werden die Haare inkl. Haarwurzel entfernt.",
    "warning": "Um langfristig weniger Haarwuchs zu erreichen empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Harzen: Brust",
    "slug": "harzen:-brust",
    "duration": "30",
    "price": "40",
    "new": "nein",
    "onSale": "nein",
    "description": "Harzen ist eine Haarentfernungsmethode mit warmem Wachs. Dabei wird das Warmwachs in Wuchsrichtung auf die Brust aufgetragen. Diese wird im Anschluss mit einem Vliesstreifen gegen die Wuchsrichtung mit einem Ruck abgezogen. Dabei werden die Haare inkl. Haarwurzel entfernt.",
    "warning": "Um langfristig weniger Haarwuchs zu erreichen empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Harzen: Rücken",
    "slug": "harzen:-ruecken",
    "duration": "30",
    "price": "40",
    "new": "nein",
    "onSale": "nein",
    "description": "Harzen ist eine Haarentfernungsmethode mit warmem Wachs. Dabei wird das Warmwachs in Wuchsrichtung auf den Rücken aufgetragen. Diese wird im Anschluss mit einem Vliesstreifen gegen die Wuchsrichtung mit einem Ruck abgezogen. Dabei werden die Haare inkl. Haarwurzel entfernt.",
    "warning": "Um langfristig weniger Haarwuchs zu erreichen empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Harzen: Unterarme",
    "slug": "harzen:-unterarme",
    "duration": "30",
    "price": "25",
    "new": "nein",
    "onSale": "nein",
    "description": "Harzen ist eine Haarentfernungsmethode mit warmem Wachs. Dabei wird das Warmwachs in Wuchsrichtung auf die Unterarme aufgetragen. Diese wird im Anschluss mit einem Vliesstreifen gegen die Wuchsrichtung mit einem Ruck abgezogen. Dabei werden die Haare inkl. Haarwurzel entfernt.",
    "warning": "Um langfristig weniger Haarwuchs zu erreichen empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Sugaring: Beine bis Knie",
    "slug": "sugaring:-beine-bis-knie",
    "duration": "45",
    "price": "30",
    "new": "nein",
    "onSale": "nein",
    "description": "Sugaring gilt als eine besonders sanfte Haarentfernungsmethode. Dabei wird eine natürliche, gut verträgliche Zuckerpaste gegen die Haarwuchsrichtung abgezogen. Durch die desinfizierenden und regenerierenden Eigenschaften der Paste, fühlt sich die Haut nach der Behandlung seidig weich an.",
    "warning": "Besonders gut geeignet für Allergiker, bei empfindlicher Haut oder Venenproblemen. Für langfristige Erfolge empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Sugaring: Beine komplett",
    "slug": "sugaring:-beine-komplett",
    "duration": "55",
    "price": "50",
    "new": "nein",
    "onSale": "nein",
    "description": "Sugaring gilt als eine besonders sanfte Haarentfernungsmethode. Dabei wird eine natürliche, gut verträgliche Zuckerpaste gegen die Haarwuchsrichtung abgezogen. Durch die desinfizierenden und regenerierenden Eigenschaften der Paste, fühlt sich die Haut nach der Behandlung seidig weich an.",
    "warning": "Besonders gut geeignet für Allergiker, bei empfindlicher Haut oder Venenproblemen. Für langfristige Erfolge empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Sugaring: Oberlippe",
    "slug": "sugaring:-oberlippe",
    "duration": "15",
    "price": "9",
    "new": "nein",
    "onSale": "nein",
    "description": "Sugaring gilt als eine besonders sanfte Haarentfernungsmethode. Dabei wird eine natürliche, gut verträgliche Zuckerpaste gegen die Haarwuchsrichtung abgezogen. Durch die desinfizierenden und regenerierenden Eigenschaften der Paste, fühlt sich die Haut nach der Behandlung seidig weich an.",
    "warning": "Besonders gut geeignet für Allergiker, bei empfindlicher Haut oder Venenproblemen. Für langfristige Erfolge empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Sugaring: Kinn",
    "slug": "sugaring:-kinn",
    "duration": "15",
    "price": "9",
    "new": "nein",
    "onSale": "nein",
    "description": "Sugaring gilt als eine besonders sanfte Haarentfernungsmethode. Dabei wird eine natürliche, gut verträgliche Zuckerpaste gegen die Haarwuchsrichtung abgezogen. Durch die desinfizierenden und regenerierenden Eigenschaften der Paste, fühlt sich die Haut nach der Behandlung seidig weich an.",
    "warning": "Besonders gut geeignet für Allergiker, bei empfindlicher Haut oder Venenproblemen. Für langfristige Erfolge empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Sugaring: Bikinizone",
    "slug": "sugaring:-bikinizone",
    "duration": "30",
    "price": "25",
    "new": "nein",
    "onSale": "nein",
    "description": "Sugaring gilt als eine besonders sanfte Haarentfernungsmethode. Dabei wird eine natürliche, gut verträgliche Zuckerpaste gegen die Haarwuchsrichtung abgezogen. Durch die desinfizierenden und regenerierenden Eigenschaften der Paste, fühlt sich die Haut nach der Behandlung seidig weich an.",
    "warning": "Besonders gut geeignet für Allergiker, bei empfindlicher Haut oder Venenproblemen. Für langfristige Erfolge empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Sugaring: Brust",
    "slug": "sugaring:-brust",
    "duration": "45",
    "price": "50",
    "new": "nein",
    "onSale": "nein",
    "description": "Sugaring gilt als eine besonders sanfte Haarentfernungsmethode. Dabei wird eine natürliche, gut verträgliche Zuckerpaste gegen die Haarwuchsrichtung abgezogen. Durch die desinfizierenden und regenerierenden Eigenschaften der Paste, fühlt sich die Haut nach der Behandlung seidig weich an.",
    "warning": "Besonders gut geeignet für Allergiker, bei empfindlicher Haut oder Venenproblemen. Für langfristige Erfolge empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Sugaring: Rücken",
    "slug": "sugaring:-ruecken",
    "duration": "45",
    "price": "45",
    "new": "nein",
    "onSale": "nein",
    "description": "Sugaring gilt als eine besonders sanfte Haarentfernungsmethode. Dabei wird eine natürliche, gut verträgliche Zuckerpaste gegen die Haarwuchsrichtung abgezogen. Durch die desinfizierenden und regenerierenden Eigenschaften der Paste, fühlt sich die Haut nach der Behandlung seidig weich an.",
    "warning": "Besonders gut geeignet für Allergiker, bei empfindlicher Haut oder Venenproblemen. Für langfristige Erfolge empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Sugaring: Unterarme",
    "slug": "sugaring:-unterarme",
    "duration": "30",
    "price": "30",
    "new": "nein",
    "onSale": "nein",
    "description": "Sugaring gilt als eine besonders sanfte Haarentfernungsmethode. Dabei wird eine natürliche, gut verträgliche Zuckerpaste gegen die Haarwuchsrichtung abgezogen. Durch die desinfizierenden und regenerierenden Eigenschaften der Paste, fühlt sich die Haut nach der Behandlung seidig weich an.",
    "warning": "Besonders gut geeignet für Allergiker, bei empfindlicher Haut oder Venenproblemen. Für langfristige Erfolge empfehle ich mindestens drei Behandlungen in regelmäßigen Abständen."
  },
  {
    "category": "body",
    "title": "Anti Cellulite Wickel",
    "slug": "anti-cellulite-wickel",
    "duration": "45",
    "price": "50",
    "new": "nein",
    "onSale": "nein",
    "description": "Durch einen Cellulite Wickel wird das Gewebe entschlackt, die Fettverbrennung gesteigert und die Haut gestrafft. Der Vorteil: Umfangsreduktion, strafferes Bindegewebe und weniger Cellulite an Beinen, Bauch und Po. Wertvolle Spurenelemente, Kräuter und Mineralien sorgen für den WOW-Effekt nach der Behandlung.",
    "warning": "Nicht geeignet für Schwangere, Personen mit Herz-Kreislaufbeschwerden, Diabetes oder offenen Wunden."
  },
  {
    "category": "body",
    "title": "Anti Cellulite Wickel 5er Kur",
    "slug": "anti-cellulite-wickel-5er-kur",
    "duration": "45",
    "price": "210",
    "new": "nein",
    "onSale": "ja",
    "description": "Durch einen Cellulite Wickel wird das Gewebe entschlackt, die Fettverbrennung gesteigert und die Haut gestrafft. Der Vorteil: Umfangsreduktion, strafferes Bindegewebe und weniger Cellulite an Beinen, Bauch und Po. Wertvolle Spurenelemente, Kräuter und Mineralien sorgen für den WOW-Effekt nach der Behandlung.",
    "warning": "Nicht geeignet für Schwangere, Personen mit Herz-Kreislaufbeschwerden, Diabetes oder offenen Wunden."
  },
  {
    "category": "body",
    "title": "Anti Cellulite Wickel 10er Kur",
    "slug": "anti-cellulite-wickel-10er-kur",
    "duration": "45",
    "price": "420",
    "new": "nein",
    "onSale": "ja",
    "description": "Durch einen Cellulite Wickel wird das Gewebe entschlackt, die Fettverbrennung gesteigert und die Haut gestrafft. Der Vorteil: Umfangsreduktion, strafferes Bindegewebe und weniger Cellulite an Beinen, Bauch und Po. Wertvolle Spurenelemente, Kräuter und Mineralien sorgen für den WOW-Effekt nach der Behandlung.",
    "warning": "Nicht geeignet für Schwangere, Personen mit Herz-Kreislaufbeschwerden, Diabetes oder offenen Wunden."
  },
  {
    "category": "makeup",
    "title": "Braut Make-Up Normal",
    "slug": "braut-make-up-normal",
    "duration": "90",
    "price": "210",
    "new": "nein",
    "onSale": "nein",
    "description": "Dein Make-Up für deinen perfekten Tag. Mir ist es wichtig, deine Wünsche bestmöglich zu erfüllen. Dabei achte ich genau auf deinen Hauttyp und stimme deinen Look passend auf dein gesamtes Erscheinungsbild ab.",
    "warning": "Inkludiert ist Inkludiert ist auch die Anfahrt (20km) am Hochzeitstag und auch ein Probeschminken davor. Hier nehme ich mir bewusst Zeit um für dich deinen perfekten Make Up Look für deinen großen Tag zu finden."
  },
  {
    "category": "makeup",
    "title": "Braut Make-Up Air Brush",
    "slug": "braut-make-up-air-brush",
    "duration": "90",
    "price": "250",
    "new": "nein",
    "onSale": "nein",
    "description": "Dein Make up für deinen perfekten Tag. Mir ist es wichtig, deine Wünsche bestmöglich zu erfüllen. Dabei achte ich genau auf deinen Hauttyp und stimme deinen Look passend auf dein gesamtes Erscheinungsbild ab. Mit der Air Brush Methode hält dein Make-Up makellos den ganzen Tag, perfekt für einen heißen Sommertag.",
    "warning": "Inkludiert ist auch die Anfahrt (20km) am Hochzeitstag und ein Probeschminken davor. Hier nehme ich mir bewusst Zeit um für dich deinen perfekten Make Up Look für deinen großen Tag zu finden."
  },
  {
    "category": "makeup",
    "title": "Abend Make-Up",
    "slug": "abend-make-up",
    "duration": "45",
    "price": "78",
    "new": "nein",
    "onSale": "nein",
    "description": "Für besondere Anlässe braucht es besondere Looks. Passend zu deinem Outfit und Vorstellungen werde ich dein Make-Up abstimmen. Glamourös und auffällig, oder doch lieber dezent und elegant? Du entscheidest.",
    "warning": "Schicke mir gerne im Vorhinein ein Foto von deinem Outfit."
  },
  {
    "category": "makeup",
    "title": "Tages Make-Up",
    "slug": "tages-make-up",
    "duration": "20",
    "price": "35",
    "new": "nein",
    "onSale": "nein",
    "description": "Abgestimmt auf deinen Typ ein alltagstaugliches Make-Up.",
    "warning": ""
  },
  {
    "category": "makeup",
    "title": "Wimpern kleben",
    "slug": "wimpern-kleben",
    "duration": "15",
    "price": "15",
    "new": "nein",
    "onSale": "nein",
    "description": "Für den perfekten Augenaufschlag können künstliche Wimpern geklebt werden um deinen Look abzurunden.",
    "warning": ""
  }
]
