import productBG from "./../../img/Produkte/bg-produkte.jpg";
import aboutMeBG from "./../../img/About-me/about-me_bg.jpg";
import offerBG from "./../../img/Angebot/bg-angebot.jpg";

class imgView {
  lazyLoadingImg() {
    const imgTargets = document.querySelectorAll("img[data-src]");

    // LAZY LOADING OF IMAGES BELOW INITIAL VIEW
    const loadImg = function (entries, observer) {
      entries.forEach((entry) => {
        // safe guard
        if (!entry.isIntersecting) return;

        // replace src with data-src
        //entry.target.src = entry.target.dataset.src;
        if (entry.target.dataset.src.includes("bg-produkte")) {
          entry.target.src = productBG;
        } else if (entry.target.dataset.src.includes("about-me_bg")) {
          entry.target.src = aboutMeBG;
        } else if (entry.target.dataset.src.includes("bg-angebot")) {
          entry.target.src = offerBG;
        } else {
          console.log("NO IMG SOURCE MATCH");
        }

        // remove blur only when loading of image is completed
        entry.target.addEventListener("load", function () {
          entry.target.classList.remove("lazy-img");
        });

        observer.unobserve(entry.target);
      });
    };

    const imgObserver = new IntersectionObserver(loadImg, {
      root: null,
      threshold: 0,
      rootMargin: "200px",
    });

    imgTargets.forEach((img) => imgObserver.observe(img));
  }
}

export default new imgView();
