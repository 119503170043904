class checkoutView {
  addHandlerCheckout(handler) {
    const checkoutBtn = document.querySelector(".booking__form--btn");
    const userForm = document.querySelector(".booking__form");

    checkoutBtn.addEventListener("click", (e) => {
      if (userForm.checkValidity()) {
        e.preventDefault();
        const firstName = document.getElementById("firstname").value;
        const lastName = document.getElementById("lastname").value;
        const email = document.getElementById("email").value;
        const phone = document.getElementById("phone").value;
        const message = document.getElementById("message").value;

        handler(firstName, lastName, email, phone, message);
      }
    });
  }

  renderSpinner() {
    document.body.innerHTML += '<div class="overlay"></div>';
    document.body.innerHTML +=
      '<div class="lds-roller lds-roller__checkout"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>';
  }
}

export default new checkoutView();
