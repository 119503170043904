export default class View {
  render(flgTrue) {
    const markup = this._generateMarkup(flgTrue);
    this._parentElement.insertAdjacentHTML("afterbegin", markup);
  }

  _clear() {
    this._parentElement.innerHTML = "";
  }
}
