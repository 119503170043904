class smoothRevealView {
  handleSmoothReveal() {
    const allSections = document.querySelectorAll(".section");

    const sectionObserver = new IntersectionObserver(this._revealSection, {
      root: null,
      threshold: 0.15,
    });

    allSections.forEach(function (section) {
      sectionObserver.observe(section);
      section.classList.add("hidden");
    });
  }

  _revealSection(entries, observer) {
    entries.forEach((entry) => {
      // safe guard
      if (!entry.isIntersecting) return;

      entry.target.classList.remove("hidden");
      entry.target.classList.add("section--active");

      observer.unobserve(entry.target);
    });
  }
}

export default new smoothRevealView();
