import _ from "lodash";
import { AJAX } from "./helpers";
import { API_URL } from "./config";
import {
  START_MORNING,
  END_EVENING,
  TIME_SLOT_DIFF,
  TIME_BUFFER,
} from "./config";

export const state = {
  bookings: [],
  summaries: {
    totalTime: 0,
    totalSum: 0,
  },
  selectedDate: Date.now(),
  availableTimeSlots: [],
  selectedTimeSlot: {
    startTime: "",
    endTime: "",
  },
};

const _checkVisited = function () {
  if (state.hasOwnProperty("websiteVisit")) {
    if (state.websiteVisit.hasOwnProperty("visitDate")) {
      if (Date.now() - state.websiteVisit.visitDate > 3 * 24 * 60 * 60 * 1000) {
        state.websiteVisit.visitDate = Date.now();
        state.websiteVisit.visitedWebsite = false;
        _persistWebsiteVisit();
      } else {
        state.websiteVisit.visitedWebsite = true;
        _persistWebsiteVisit();
      }
    }
  } else {
    state.websiteVisit = {};
    state.websiteVisit.visitDate = Date.now();
    state.websiteVisit.visitedWebsite = false;
    _persistWebsiteVisit();
  }
};

const _persistWebsiteVisit = function () {
  localStorage.setItem("websiteVisit", JSON.stringify(state.websiteVisit));
};

export const addBooking = function (booking) {
  // Add bookmark
  state.bookings.push(booking);
  state.bookings = _.uniqBy(state.bookings, "slug");

  persistsBookings();
};

export const deleteBooking = function (slug) {
  // Delete bookmark
  const index = state.bookings.findIndex((el) => el.slug === slug);
  state.bookings.splice(index, 1);

  persistsBookings();
};

export const getFreeTimeSlots = async function () {
  try {
    // get selected date in correct format for API call
    const filterDate = formatDate(state.selectedDate);

    // initialize array
    const availableTimeSlots = [];

    const freeTimeSlotsCalendar = await _getFreeCalendarSlots(filterDate);

    state.availableTimeSlots = [];

    freeTimeSlotsCalendar.forEach((slot) => {
      const timeSlotDuration = (slot.endFree - slot.startFree) / (1000 * 60); // minutes

      const nextFullInterval = getNextFullInterval(
        slot.startFree,
        TIME_SLOT_DIFF
      );

      for (
        let i = 0;
        i <
        Math.ceil(
          (timeSlotDuration / state.summaries.totalTime) *
            (60 / TIME_SLOT_DIFF + 1)
        ) +
          1;
        i++
      ) {
        const timeCheckSumDuration =
          timeSlotDuration -
          nextFullInterval.offsetMinutes -
          TIME_BUFFER -
          i * TIME_SLOT_DIFF -
          state.summaries.totalTime;

        const timeCheckSumDay =
          nextFullInterval.freeTimeSlotDate.getTime() - Date.now();

        if (timeCheckSumDuration > 0 && timeCheckSumDay > 0) {
          const timeSlotStart = new Date(
            nextFullInterval.freeTimeSlotDate.getTime() +
              i * TIME_SLOT_DIFF * 60 * 1000
          );
          const timeSlotEnd = new Date(
            timeSlotStart.getTime() + state.summaries.totalTime * 60 * 1000
          );

          state.availableTimeSlots.push({ timeSlotStart, timeSlotEnd });
        }
      }
    });

    persistsBookings();
  } catch (err) {
    console.error(err);
  }
};

export const addDate = function (date) {
  state.selectedDate = date;

  persistsBookings();
};

export const addSelectedTimeSlot = function (startTime, endTime) {
  state.selectedTimeSlot.startTime = startTime;
  state.selectedTimeSlot.endTime = endTime;

  persistsBookings();
};

const calcBookingsSummaries = function () {
  let totalTime = 0;
  let totalSum = 0;

  state.bookings.forEach((booking) => {
    totalTime += booking.duration * 1;
    totalSum += booking.price * 1;
  });

  state.summaries.totalSum = totalSum;
  state.summaries.totalTime = totalTime;
};

const persistsBookings = function () {
  calcBookingsSummaries();
  localStorage.setItem("bookings", JSON.stringify(state.bookings));
  localStorage.setItem("summaries", JSON.stringify(state.summaries));
  localStorage.setItem("selectedDate", JSON.stringify(state.selectedDate));
  localStorage.setItem(
    "availableTimeSlots",
    JSON.stringify(state.availableTimeSlots)
  );
  localStorage.setItem(
    "selectedTimeSlot",
    JSON.stringify(state.selectedTimeSlot)
  );
};

export const clearBookings = function () {
  localStorage.removeItem("bookings");
  localStorage.removeItem("summaries");
  localStorage.removeItem("selectedDate");
  localStorage.removeItem("availableTimeSlots");
  localStorage.removeItem("selectedTimeSlot");
};

const formatDate = function (date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const getNextFullInterval = function (date, interval) {
  let d = new Date(date);

  const year = d.getFullYear();
  const monthIndex = d.getMonth();
  const day = d.getDate();
  const hours = d.getHours();
  const minutes = d.getMinutes();

  let minuteSlot;
  let hourSlot;
  let offsetMinutes;

  for (let i = 0; i < Math.floor(60 / interval) + 1; i++) {
    minuteSlot = interval * i;
    if (minuteSlot >= minutes) break;
  }

  if (minuteSlot === 60) {
    hourSlot = hours + 1;
    minuteSlot = 0;
    offsetMinutes = 60 - minutes;
  } else {
    hourSlot = hours;
    offsetMinutes = minuteSlot - minutes;
  }

  const freeTimeSlotDate = new Date(
    year,
    monthIndex,
    day,
    hourSlot,
    minuteSlot,
    0,
    0
  );

  return {
    freeTimeSlotDate,
    offsetMinutes,
  };
};

const _getFreeCalendarSlots = async function (filterDate) {
  // get booked time slots from Google calendar
  const bookedEvents = await AJAX(
    `${API_URL}/api/v1/calendar?minDate=${filterDate}T00:00:00.000Z&maxDate=${filterDate}T23:59:59.999`
  );

  // set earliest start
  const startDateMorning = new Date(
    `${filterDate}T0${START_MORNING}:00:00.000`
  );

  // set latest end
  const endDateEvening = new Date(`${filterDate}T${END_EVENING}:00:00.000`);

  let startFree;
  let endFree;
  let duration;

  // initialize free time slots
  const freeTimeSlotsCalendar = [];

  if (bookedEvents.bookedEvents.length > 0) {
    freeTimeSlotsCalendar.push({
      startFree: startDateMorning,
      endFree: new Date(bookedEvents.bookedEvents[0].start.dateTime),
    });

    for (let i = 0; i < bookedEvents.bookedEvents.length - 1; i++) {
      startFree = new Date(bookedEvents.bookedEvents[i].end.dateTime);
      endFree = new Date(bookedEvents.bookedEvents[i + 1].start.dateTime);
      freeTimeSlotsCalendar.push({
        startFree,
        endFree,
      });
    }

    freeTimeSlotsCalendar.push({
      startFree: new Date(
        bookedEvents.bookedEvents[
          bookedEvents.bookedEvents.length - 1
        ].end.dateTime
      ),
      endFree: endDateEvening,
    });
  } else {
    freeTimeSlotsCalendar.push({
      startFree: startDateMorning,
      endFree: endDateEvening,
    });
  }

  return freeTimeSlotsCalendar;
};

const init = function () {
  const bookingStorage = localStorage.getItem("bookings");
  if (bookingStorage) state.bookings = JSON.parse(bookingStorage);

  const summariesStorage = localStorage.getItem("summaries");
  if (summariesStorage) state.summaries = JSON.parse(summariesStorage);

  const selectedDateStorage = localStorage.getItem("selectedDate");
  if (selectedDateStorage) state.selectedDate = JSON.parse(selectedDateStorage);

  const timeSlotStorage = localStorage.getItem("availableTimeSlots");
  if (timeSlotStorage) state.availableTimeSlots = JSON.parse(timeSlotStorage);

  const slectedTimeSlotStorage = localStorage.getItem("selectedTimeSlot");
  if (slectedTimeSlotStorage)
    state.selectedTimeSlot = JSON.parse(slectedTimeSlotStorage);

  const websiteVisitStorage = localStorage.getItem("websiteVisit");
  if (websiteVisitStorage) state.websiteVisit = JSON.parse(websiteVisitStorage);

  _checkVisited();
};

init();
