import Litepicker from "litepicker";
import scrollDownIcon from "../../img/Icons/scroll-down.svg";
import { CLOSED_DAYS } from "../config";

class calendarView {
  addHandlerCalendar(handler) {
    window.disableLitepickerStyles = true;

    const calendarWrapper = document.querySelector(".calendar__wrapper");

    const picker = new Litepicker({
      element: document.getElementById("datepicker"),
      inlineMode: true,
      singleMode: true,
      lang: "de-DE",
      autoApply: true,
      autoRefresh: true,
      maxDays: 1,
      //   tooltipText: {
      //     one: "night",
      //     other: "nights",
      //   },
      lockDaysFilter: (day) => {
        const d = day.getDay();
        const weekendCondition = CLOSED_DAYS.includes(d);
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        const passedDayCondition = day.getTime() < today.getTime();

        return weekendCondition || passedDayCondition;
      },
      tooltipNumber: (totalDays) => {
        return totalDays - 1;
      },
    });
    picker.setDate(Date.now(), true);

    calendarWrapper.addEventListener("click", function (e) {
      const timeSlotWrapper = document.querySelector(".time-slots__wrapper");

      if (timeSlotWrapper.classList.contains("hidden")) {
        timeSlotWrapper.classList.remove("hidden");
        timeSlotWrapper.classList.add("time-slots__wrapper--active");
      }

      const selectedDate = picker.getDate();
      const selectedWeekday = selectedDate.dateInstance.getDay();
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      if (
        CLOSED_DAYS.includes(selectedWeekday) ||
        selectedDate.dateInstance.getTime() < today.getTime()
      )
        return;
      handler(selectedDate.dateInstance);
    });
  }

  renderTimeSlots(timeSlots, selectedDate) {
    const timeSlotWrapper = document.querySelector(".time-slots__wrapper");

    timeSlotWrapper.innerHTML = "";

    const inputDate = new Date(selectedDate);

    const weekday = inputDate.toLocaleDateString("de-AT", {
      weekday: "long",
    });
    const day = "" + inputDate.getDate();
    const month = "" + (inputDate.getMonth() + 1);
    const year = "" + inputDate.getFullYear();

    const dateMarkup = this._generateDateMarkup(weekday, day, month, year);

    timeSlotWrapper.insertAdjacentHTML("beforeend", dateMarkup);

    if (timeSlots.length === 0) {
      const notAvailableMarkup = this._generateNoSlotMarkup();
      timeSlotWrapper.insertAdjacentHTML("beforeend", notAvailableMarkup);
      return;
    }

    timeSlots.forEach((timeSlot) => {
      const startTime = this._getTime(timeSlot.timeSlotStart);
      const endTime = this._getTime(timeSlot.timeSlotEnd);

      const markup = this._generateMarkup(startTime, endTime);

      timeSlotWrapper.insertAdjacentHTML("beforeend", markup);
    });
  }

  handleDateSelection(handlerSelectedDate) {
    const timeSlots = document.querySelectorAll(".time-slot");
    const bookingForm = document.querySelector(".booking__form");

    timeSlots.forEach((slot) => {
      slot.addEventListener("click", (e) => {
        e.preventDefault();
        const activeTimeSlot = document.querySelector(".time-slot__selected");

        if (activeTimeSlot) {
          activeTimeSlot.classList.remove("time-slot__selected");
        }

        e.target.classList.add("time-slot__selected");

        if (bookingForm.classList.contains("hidden")) {
          bookingForm.classList.remove("hidden");
          bookingForm.classList.add("booking__form--active");
        }

        const startTime =
          e.target.dataset.hourStart + e.target.dataset.minuteStart;
        const endTime = e.target.dataset.hourEnd + e.target.dataset.minuteEnd;

        handlerSelectedDate(startTime, endTime);
      });
    });
  }

  renderSpinner() {
    const timeSlotWrapper = document.querySelector(".time-slots__wrapper");

    const markup = `<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`;

    timeSlotWrapper.innerHTML = "";

    timeSlotWrapper.insertAdjacentHTML("beforeend", markup);
  }

  _generateMarkup(startTime, endTime) {
    return `<div class="time-slot" data-hour-start="${startTime.hours}" data-minute-start="${startTime.minutes}" data-hour-end="${endTime.hours}" data-minute-end="${endTime.minutes}">
    ${startTime.hours}:${startTime.minutes} &ndash; ${endTime.hours}:${endTime.minutes}
    </div>`;
  }

  _generateDateMarkup(weekday, day, month, year) {
    return `<div class="time-slot__date">
    ${weekday}, ${day}.${month}.${year}
    </div>`;
  }

  _generateNoSlotMarkup() {
    return `<div class="time-slot__not-available">
    Zum ausgewählten Datum gibt es leider keine freien Termine mehr.
    </div>`;
  }

  _getTime(date) {
    const d = new Date(date);

    let hours = "" + d.getHours();
    let minutes = "" + d.getMinutes();

    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;

    return { hours, minutes };
  }
}

export default new calendarView();
