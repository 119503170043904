import View from "./view";

import footerLogo from "./../../img/Logos/logo-aufgehuebscht.png";
import logoInstagram from "./../../img/Icons/instagram.svg";
import logoFacebook from "./../../img/Icons/facebook.svg";
import logoWhatsapp from "./../../img/Icons/whatsapp.svg";

const currentYearCopyright = new Date().getFullYear();

const whatsappPhoneNumber = 436802151892;

class footerView extends View {
  _parentElement = document.querySelector(".footer");

  _generateMarkup(flgTrue) {
    return `
    <div class="footer__container">
    
        <div class="footer__logo-aufgehuebscht--container">
            <a href="/">
                <img
                    src="${footerLogo}"
                    alt="Logo aufgehuebscht by Lisa Brandstetter"
                    class="footer__logo-aufgehuebscht"
                />
            </a>
        </div>

        <div class="footer__link-container">
        <div class="footer__link-container--element">
            <a href="contact.html" class="footer__link-container--link">Kontakt</a>
        </div>

        <div class="footer__link-container--element">
            <a href="impressum.html" class="footer__link-container--link">Impressum</a>
        </div>

        <div class="footer__link-container--element">
            <a href="agb.html" class="footer__link-container--link">AGB</a>
        </div>

        <div class="footer__link-container--element">
            <a href="privacy.html" class="footer__link-container--link">Datenschutz</a>
        </div>

        <div class="footer__link-container--element">
            <a href="cookieSettings.html" class="footer__link-container--link">Cookie-Settings</a>
        </div>
    </div>

        <div class="footer__social-media--logo-container">
            <a href="https://www.instagram.com/aufgehuebschtvon_lisa/" class="link__fb" target="_blank" rel="noopener noreferrer"
            >
            <img
                src="${logoInstagram}"
                alt="Instagram aufgehuebscht"
                class="footer__social-media--logo"
            /> 
            </a>
            <a href="https://www.facebook.com/aufgehuebschtvonlisa" class="link__fb" target="_blank" rel="noopener noreferrer"
            ><img
                src="${logoFacebook}"
                alt="Facebook aufgehuebscht"
                class="footer__social-media--logo"
            />
            </a>
            <a href="https://wa.me/${whatsappPhoneNumber}" class="link__fb" target="_blank" rel="noopener noreferrer"
            >
            <img
                src="${logoWhatsapp}"
                alt="Instagram aufgehuebscht"
                class="footer__social-media--logo"
            /> 
            </a>
        </div>

        <div class="footer__copyright">
        <p>
            &copy;
            <span class="footer__copyright--year">${currentYearCopyright}</span> aufgehübscht von
            Lisa Brandstetter
        </p>
        <p>Designed and developed by Michael Weitz</p>
        </div>

        </div>

        <!-- Workaround for transition bug in chrome -->
        <script></script>`;
  }
}

export default new footerView();
