class offerSmoothRevealView {
  offerSmoothReveal() {
    const allOfferContainers = document.querySelectorAll(
      ".behandlungen__container"
    );

    const sectionObserver = new IntersectionObserver(this._revealSection, {
      root: null,
      threshold: 0.1,
    });

    allOfferContainers.forEach(function (section) {
      sectionObserver.observe(section);
      section.childNodes.forEach((child) => {
        if (child.nodeName === "#text") return;
        child.classList.forEach((cl) => {
          if (cl.includes("left")) child.classList.add("translate-left");
          if (cl.includes("right")) child.classList.add("translate-right");
        });
      });
    });
  }

  _revealSection(entries, observer) {
    entries.forEach((entry) => {
      // safe guard
      if (!entry.isIntersecting) return;

      entry.target.childNodes.forEach((child) => {
        if (child.nodeName === "#text") return;
        child.classList.remove("translate-left");
        child.classList.remove("translate-right");
        //child.classList.add("behandlungen--active");
      });

      observer.unobserve(entry.target);
    });
  }
}

export default new offerSmoothRevealView();
